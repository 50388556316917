import React, { useEffect, useState } from "react";
import {
  createRegime,
  getRegimes,
  updateRegimeStatus,
} from "../apiClient/operations/regimesOperations";
import { getTaxRegimeList } from "../apiClient/operations/sat";

import MenuButton from "../tailwindUI/MenuButton";
import { EllipsisVerticalIcon } from "@heroicons/react/24/solid";

export function useRegime({ accountId }) {
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [regimes, setRegimes] = useState([]);
  const [regimesList, setRegimesList] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [dataToUpdate, setDataToUpdate] = useState(null);

  const actionColums = [
    {
      id: 1,
      name: "Activar regímen",
      action: (item) => {
        if (item.status === "active") return;
        setShowModal(true);
        setDataToUpdate({
          regime_id: item.id,
          status: "active",
        });
      },
    },
    {
      id: 2,
      name: "Desactivar regímen",
      action: (item) => {
        if (item.status === "inactive") return;
        setShowModal(true);
        setDataToUpdate({
          regime_id: item.id,
          status: "inactive",
        });
      },
    },
  ];

  const getRegimeActions = (currentStatus, regimeId) => {
    const regimesActions = {
      all: [
        {
          id: 1,
          name: "Activar regímen",
          action: () => {
            setShowModal(true);
            setDataToUpdate({
              regime_id: regimeId,
              status: "active",
            });
          },
        },
        {
          id: 2,
          name: "Desactivar regímen",
          action: () => {
            setShowModal(true);
            setDataToUpdate({
              regime_id: regimeId,
              status: "inactive",
            });
          },
        },
      ],
      inactive: [
        {
          id: 1,
          name: "Activar regímen",
          action: () => {
            setShowModal(true);
            setDataToUpdate({
              regime_id: regimeId,
              status: "active",
            });
          },
        },
      ],
      active: [
        {
          id: 1,
          name: "Desactivar regímen",
          action: () => {
            setShowModal(true);
            setDataToUpdate({
              regime_id: regimeId,
              status: "inactive",
            });
          },
        },
      ],
    };

    return regimesActions[currentStatus || "all"]
  };

  const getRegimesData = async () => {
    try {
      setIsLoading(true);
      const response = await getRegimes(accountId);
      const regimes = response.data.map((regime) => ({
        ...regime.tax_regime,
        id: regime.id,
        type: regime.tax_regime.type.map((type) =>
          type == "personal" ? "Persona Física" : "Persona Moral"
        ),
        status: regime?.status || "",
        actions: (
          <div className="flex lg:justify-end lg:pr-12">
            <MenuButton
              items={getRegimeActions(regime?.status, regime.id)}
              itemW={280}
            >
              <EllipsisVerticalIcon className="w-6 h-6 text-gray-900" />
            </MenuButton>
          </div>
        ),
      }));

      setRegimes(regimes);
    } catch (error) {
      setError(error);
    } finally {
      setIsLoading(false);
    }
  };

  const getRegimesList = async () => {
    const response = await getTaxRegimeList();
    const list = response.map((regime) => ({
      id: regime.id,
      description: regime.descripcion,
    }));
    setRegimesList(list);
  };

  const updateRegime = async (data) => {
    try {
      await updateRegimeStatus(accountId, data);
      await getRegimesData();
    } catch (error) {
      setError(error);
    }
  };

  const addNewRegime = async (regimeId) => {
    try {
      setIsLoading(true);
      await createRegime(accountId, {
        regime_id: regimeId,
      });
      await getRegimesData();
    } catch (error) {
      setError(error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (accountId) {
      getRegimesData();
    }
  }, [accountId]);

  useEffect(() => {
    getRegimesList();
  }, []);

  return {
    regimes,
    regimesList,
    actionColums,
    dataToUpdate,
    getRegimeActions,
    error,
    isLoading,
    updateRegime,
    addNewRegime,
    showModal,
    setShowModal,
  };
}
