import React, { useContext, useEffect, useState } from "react";
import Table from "../../tailwindUI/Table";

import PropTypes from "prop-types";

import EmptyState from "../../tailwindUI/EmptyState";
import PrimaryButton from "../../tailwindUI/PrimaryButton";
import PermissionsGate from "../../cognito/permissions/PermissionsGate";
import { ROLES } from "../../cognito/permissions/permission-maps";
import { AlertContext } from "../../context/AlertContext";
import { useRegime } from "../../hooks/useRegime";
import Modal from "../../hooks/Modal";
import Button from "../../hooks/Button";
import { UpdateRegimeStatusModal } from "./UpdateRegimeStatusModal";
import { XMarkIcon } from "@heroicons/react/24/solid";
import SecondaryLoader from "../../hooks/SecondaryLoader";

const tableColumns = [
  { heading: "Nombre de Regimen", value: "name", main: true },
  { heading: "Regimen Id", value: "regime_id" },
  { heading: "Tipo de Persona", value: "type" },
  { heading: "Estatus", value: "status", badge: true },
  { heading: "Acciones", value: "actions" },
];

function RegimenInfo({ account }) {
  const { setAlert } = useContext(AlertContext);
  const [openModal, setOpenModal] = useState(false);
  const [selectedRegime, setSelectedRegime] = useState(null);
  const [showLoader, setShowLoader] = useState(false);
  const {
    regimes,
    regimesList,
    dataToUpdate,
    actionColums,
    updateRegime,
    addNewRegime,
    showModal,
    setShowModal,
    isLoading,
    error,
  } = useRegime({
    accountId: account.id,
  });

  const addRegime = async () => {
    try {
      setShowLoader(true);
      setOpenModal(false);
      await addNewRegime(selectedRegime);
      setAlert({
        active: true,
        type: "success",
        message: "Regímen agregado correctamente",
      });
    } catch (error) {
      setAlert({
        active: true,
        type: "error",
        message: error?.message || "Error al procesar tu solicitud",
      });
    } finally {
      setSelectedRegime(null);
      setShowLoader(false);
    }
  };

  useEffect(() => {
    if (!error) return;
    setAlert({
      active: true,
      type: "error",
      message: error?.message || "Error al procesar tu solicitud",
    });
  }, [error]);

  return (
    <main>
      {showLoader && <SecondaryLoader />}
      <div className="flex items-center justify-between mb-6 mt-0">
        <h2 className="text-gray-900 font-medium text-xl flex items-center ml-2">
          Regímenes
        </h2>
        {regimes.length > 0 && (
          <PrimaryButton onClick={() => setOpenModal(true)}>
            Agregar Regímenes
          </PrimaryButton>
        )}
      </div>

      {isLoading || regimes.length > 0 ? (
        <>
          <div className="hidden lg:block">
            <Table
              title="Regímenes"
              data={regimes}
              isLoadingData={isLoading}
              columns={tableColumns}
            />
          </div>
          <div className="block lg:hidden">
            <Table
              title="Regímenes"
              data={regimes}
              isLoadingData={isLoading}
              columns={tableColumns.slice(0, tableColumns.length - 1)}
              actions={actionColums}
            />
          </div>
        </>
      ) : (
        <EmptyState
          title="Sin Regímenes"
          text="Este contribuyente no cuenta con regímenes."
        >
          <div className="w-full flex items-center justify-center mt-4">
            <div className="w-full sm:w-1/2 md:w-1/4">
              <PermissionsGate allowedRoles={[ROLES.root, ROLES.help_desk]}>
                <PrimaryButton isFullWidth onClick={() => setOpenModal(true)}>
                  <span className="text-[15px] md:text-sm py-0.5 md:py-1 font-medium">
                    Agregar regímen
                  </span>
                </PrimaryButton>
              </PermissionsGate>
            </div>
          </div>
        </EmptyState>
      )}

      <UpdateRegimeStatusModal
        showModal={showModal}
        setShowModal={setShowModal}
        setAlert={setAlert}
        dataToUpdate={dataToUpdate}
        updateStatus={updateRegime}
      />

      <Modal
        modalOpen={openModal}
        modalOpenChange={setOpenModal}
        hasTitle={false}
        maxWidth="md:max-w-lg 2xl:max-w-3xl"
        marginY="my-full"
      >
        <div className="wrelative w-full h-72 flex justify-center items-center">
          <XMarkIcon
            className="absolute w-7 h-7 right-3 top-3 cursor-pointer text-gray-500"
            onClick={() => setOpenModal(false)}
          />

          <div className="xs:w-5/6 md:w-full text-center">
            <div className="font-semibold text-xl text-v2-input-text">
              ¿Qué regímen agregarás?
            </div>
            <div className="mt-7 w-full flex justify-center">
              <select
                className="xs:w-full md:w-3/4 form-select cursor-pointer block w-full py-2 px-1.5 text-base text-gray-700 bg-white border border-solid border-light-gray rounded-5 transition ease-in-out focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                onChange={(e) => {
                  setSelectedRegime(e.target.value);
                }}
              >
                <option value={0} defaultValue>
                  Selecciona un regímen
                </option>
                {regimesList.map((regime) => (
                  <option key={regime.id} value={regime.id}>
                    {regime.id}. {regime.description}
                  </option>
                ))}
              </select>
            </div>
            <div className="mt-10 w-full flex justify-center">
              <Button
                heigth="h-12"
                width="xs:w-full md:w-3/4 lg:w-1/2"
                disabled={
                  isLoading || !selectedRegime || selectedRegime === "0"
                }
                onClick={addRegime}
              >
                Agregar
              </Button>
            </div>
          </div>
        </div>
      </Modal>
    </main>
  );
}

RegimenInfo.propTypes = {
  account: PropTypes.object,
};

export default RegimenInfo;
